.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-loader {
    img{
        width: 100%;
        height: 100%;
        max-width: 100%;
    }
}

.postThumbnail {
    padding: 0 0.5em;
}