@import '../../styles/global.scss'; 

.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: min(680px, 100%);
}

label{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: space-between;


    input{
        margin-left: 20px;
    }
}

.error{
    color: red;
    font-size: 12px;
    padding: 10px 0;
}
