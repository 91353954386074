.form-hero {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em;

    .form-wrapper {
        max-width: 1400px;

        .form-input {
            width: 80%;
        }

        .form-btn {
            display: flex;
            align-items: flex-start;
            width: 100%;
            justify-content: flex-start;
            margin: 0.5em 0 0 0;
            p {
                padding-right: 20px;
                margin: 20px 0px;
            }
        }

        .form-upload {
            margin: 1em 0 0 0;
            width: 50%;
        }
        .gallery {
            width: 20%;
            display: flex;
        }
    }
}
.image-area {
    width: 50%;
    height: 500px;
    background-color: white;
    border: 2px grey dotted;
    border-radius: 20px;
    min-width: 50%;
    margin: 0 0.5em;
    @media screen and (max-width: 600px) {
        width: 98%;
        min-width: 98%;
    }
}

.top-half {
    display: flex;
    width: 100%;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
.btm-half {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0.5em;
}

.classroot {
    width: 100%;
}

.right-wrapper {
    margin: 0 0.5em;
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: space-between;
    width: 100%;
}

.padder {
    padding: 0.5em 0;
}

button:hover {
    scale: 1;
}

#root > div > form > div > div > div.btm-half > div.form-btn > div {
    width: 50%;
    padding: 30px 0;
}

.add-wrapper {
    margin: 0.5em 0;
}

.para-wrapper {
    margin: 0.5em 0;
}

.form-wrapper {
    padding: 0.5em 0;
}

.submit-btn {
    width: 10%;
    height: 10%;
    padding: 0;
    margin-top: 10px;
}

.thumbnail {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
        margin: 0;
    }
}
