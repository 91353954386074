body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    cursor: pointer;
}

a:hover {
    opacity: 0.6;
}
a:active {
    opacity: 0.3;
    scale: 0.9;
}

button:hover {
    opacity: 0.6;
}

button:active {
    opacity: 0.3;
    scale: 0.9;
}
