.server-wrapper {
    padding: 1.5rem;
    h5 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 1em;
    }
    h6 {
        font-size: 18px;
        font-weight: 400;
    }
    p {
        font-size: 14px;
        font-weight: 400;
    }
    a{
        margin: 0;
        padding: 0;
    }
    a:hover{
        text-decoration: underline;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1.5rem;
    .box-wrapper {
        position: relative;
        margin: 10px;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        padding: 1.5rem;
        background-color: #f9f9f9;
        max-width: 1400px;
        width: 100%;
        .close-box{
            position: absolute;
            top: 10px;
            right: 10px;
        }
        label {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;
            h6 {
                font-size: 14px;
                font-weight: 400;
                width: 25%;
                margin: 0;
            }
            input{
                height: 25px;
                font-size: 14px;
            }
            .sm-txt{
                font-size: 12px;
                opacity: 0.7;
            }
            .select-box {
                width: 50%;
                select {
                    width: 100%;
                    padding: 0.5em;
                    border: 1px solid #e5e5e5;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        .dwn-btn {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            padding-top: 20px;
        }
    }
}
