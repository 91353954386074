@import '../../../styles/global.scss';

.hero{
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 100%;

    .wrapper{
        max-width: 680px;
        display: flex;
        flex-direction: column;    
        @include tablet {
            align-items: flex-start;
            justify-content: flex-start;
        }
}
.inpField{
    width: 100px;
    max-width: 100%;
}
    .input_container{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        @include tablet {
            align-items: flex-start;
            justify-content: flex-start;
            .addBtn{
                padding: 0 10px;
            }
            .btnCap{
                padding: 0 10px 0 0;
                margin: 0;
            }
        }
    }
    .btnCap{
        padding: 0 20px
    }
    .addBtn{
        padding: 0 20px
    }
}

.bodyCopy{
    max-width: 400px;
    text-align: left;
}

h3{
    font-size: 24px;
}

@include tablet{
    .genBtn{
        margin-top: 20px;
    }
}