@import '../../styles/global.scss';

.my-masonry-grid_column > div {
    margin-bottom: 30px;
    background: #eee;
    padding: 20px;
    width: 100%;
  }
  
  .thumbnail {
    width: 100%;
    height: 0;
    background-size: cover;
    background-position: center center;
    position: relative;
  }
  
  .thumbnail img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
    width: 100%;
}

.grid-item {
    width: 100%;
    position: relative;
}

.grid-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tagButton{
  margin: 0 10px;
}

.filter{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  margin: 20px 0;
  padding-right: 1rem;
  width: 100%;
}

.top-bar{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

.postBtn{
  margin: 0 0.5em;
}


.tagInput{
  width: 25%;

  @media (max-width: 600px) {
    width: 100%;
  }



  .tagInput{
    width: 100%;
  }
}  

.pagination{
  padding: 1em 0;
}

.postBtn{
  color: black;
}