@import "../../styles/global.scss";

.social-links {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
}
.social-links a {
    text-decoration: none;
    color: rgb(var(--gray));
}
.social-links a:hover {
    color: rgb(var(--gray-dark));
}

footer{
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
}