


.inputs{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
}

.button-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.container{
    width: 100%;
    max-width: 1400px;
    
}

.wrapper{
    width: 100%;
    max-width: max(1400px, 90%);
    padding: 1em;
}