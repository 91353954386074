.post-wrapper {
    max-width: 1400px;
}

.post-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 0.5em 0.5em;

    .top-half {
        display: flex;
        width: 100%;
        flex-direction: row;
        height: 100%;
        .info-box {
            padding: 0.5em 2em;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40%;
        }

        .top-title {
            width: 50%;
            img {
                max-width: 100%;
            }
        }

        .title-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }

    .web {
        background-color: #29b6f6;
        width: fit-content;
        padding: 0 1rem;
        margin: 0 0.5rem;
        border-radius: 5px;
    }
    .web:first-child {
        margin-left: 0;
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
}
