.gpt-wrapper{
    background-color: white;
    border-radius: 5px;
    min-height: 70vh;
    display: flex;
    margin: 1rem;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

.enterBtn{
    border: 0;
    margin: 0;
    padding: 0;
    color: grey;
}
.enterBtn:hover{
    opacity: 0.5;
}

.gpt-inp-wrapper{
    width: 100%;
    display: flex;
    justify-content: center ;
}
.gpt-input{
    width: 95%;
    
}