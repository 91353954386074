.doc-wrapper{
    padding: 2rem;
    .framework{
        width: 200px;
        margin-left: 20px;
        height: 100%;
        font-size: 1rem;
    }
    .box-wrapper{
        display: flex;
        align-items: center;
    }
    .greyd{
        color: grey;
        font-size: 0.9rem;
    }
    p{
        font-size: 1rem;
        margin: 0;
    }
    input{
        font-size: 1rem;
    }
}