.menu-item {
    font-size: 18px;
}
.button {
    display: flex;
    cursor: pointer;
    height: 38px;
    align-items: center;
}
.x {
    padding-left: 20px;
    position: relative;
    z-index: 20;
}

.x:hover{
    opacity: 0.6;
}
.x:active{
    opacity: 0.3;
    scale: 0.9;
}

.internal-links {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 10;
    padding: 40vh 0;

    a:hover {
        text-decoration: underline;
    }

    .sub {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.internal-links a:active {
    scale: 0.9;
}

.menu-item.mini {
    font-size: 14px;
}
