@import '../../styles/global.scss';

.hero {
  flex-direction: column;

  align-items: center;
  display: flex;

  .wrapper {
    padding-top: 25px;
    width: min(680px, 90%);
    display: flex;
    align-items: flex-start;
    .inputContainer{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 20px 0;
        label{
            width: 200px;
            min-width: 200px;
            max-width: 100%;
        }
        #input3{
            height: 25px;
            width: min(680px, 90%)
        }
        #input4{
            height: 400px;
            width: min(680px, 90%);
        } 
    }
  }
}

.disabled{
  opacity: 0;
}
.disabled:hover{
  opacity: 0;
}

button.classic {
  border: none;
  color: black;
}