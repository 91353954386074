.left-bubble{
    width: 45%;
    background-color: rgb(172, 228, 228);
    border-radius: 20px;
    margin: 1rem;
    text-align: left;
    border: gray;
    p{
        font-size: 15px;
        padding: 0 1rem;
    }
}

.left-wrap{
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.right-wrap{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.right-bubble{
    width: 45%;
    background-color: rgb(172, 228, 228);
    border-radius: 20px;
    margin: 1rem;
    text-align: left;
    border: gray;

    p{
        font-size: 15px;
        padding: 0 1rem;
    }
}