
.wrap {
    padding: 50px;
}
:root {
	--accent: #088343;
	--accent-dark: #088343;
	--black: 15, 18, 25;
	--gray: black;
	--gray-light: 229, 233, 240;
	--gray-dark: 34, 41, 57;
	--gray-gradient: rgba(var(--gray-light), 50%), #fff;
	--box-shadow: 0 2px 6px rgba(var(--gray), 25%), 0 8px 24px rgba(var(--gray), 33%),
		0 16px 32px rgba(var(--gray), 33%);
}
@font-face {
	font-family: 'Atkinson';
	src: url('../../public/fonts/atkinson-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Atkinson';
	src: url('../../public/fonts/atkinson-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
body {
	font-family: 'Atkinson', sans-serif;
	margin: 0;
	padding: 0;
	text-align: left;
	background: linear-gradient(var(--gray-gradient)) no-repeat;
	background-size: 100% 600px;
	word-wrap: break-word;
	overflow-wrap: break-word;
	color: rgb(var(--gray-dark));
	font-size: 20px;
	line-height: 1.7;
}
main {
	width: 720px;
	max-width: calc(100% - 2em);
	margin: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 0.5rem 0;
	color: rgb(var(--black));
	line-height: 1.2;
}
h1 {
	font-size: 3.052em;
	@media (max-width: 800px) {
		font-size: 1.5em;
		
	}	
}

h3 {
	font-size: 1.953em;
}
h4 {
	font-size: 1.563em;
}
h5 {
	font-size: 1.25em;
}
strong,
b {
	font-weight: 700;
}
p {
	margin-bottom: 1em;
}
.prose p {
	margin-bottom: 2em;
}
textarea {
	width: 100%;
	font-size: 16px;
}
input {
	font-size: 16px;
}
table {
	width: 100%;
}
img {
	max-width: 100%;
	height: auto;
	border-radius: 40px;
}
code {
	padding: 2px 5px;
	background-color: rgb(var(--gray-light));
	border-radius: 2px;
}
pre {
	padding: 1.5em;
	border-radius: 8px;
}
pre > code {
	all: unset;
}
blockquote {
	border-left: 4px solid var(--accent);
	padding: 0 0 0 20px;
	margin: 0px;
	font-size: 1.333em;
}
hr {
	border: none;
	border-top: 1px solid rgb(var(--gray-light));
}
@media (max-width: 720px) {
	body {
		font-size: 18px;
	}
}

.sr-only {
	border: 0;
	padding: 0;
	margin: 0;
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	white-space: nowrap;
}

button{
    all: unset;
    cursor: pointer;
}
button:hover{
    opacity: 0.8;
    scale: 1.1;
}
button:active{
	scale: 0.9;

}
a:hover{
	opacity: 0.8;
}

@mixin tablet {
	@media (max-width: 1024px) {
		@content;
	}
}


.hero-wrapper{
    width: 100%;
}

.hero{
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 80vh;

	@media screen and (max-width: 600px) {
		justify-content: flex-start;
	}
}

.hero-container{
    max-width: 680px;
}

.hero-padder{
    padding: 20px;
}

.flex-row {
	display: flex;	
}

.space-between{
	justify-content: space-between;
}